require("prismjs/themes/prism-okaidia.css")

exports.onRouteUpdate = ({ location }) => {
  if (location.pathname === '/' && document) {
    document.body.classList.add('show-slogan')
  } else {
    document.body.classList.remove('show-slogan')
  }
}


exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  return !routerProps.location.pathname.startsWith('/migration')
}
